import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AuthServiceType, EventbusType, LocalStorage, ModalType, ResourceActionFailed } from '@movecloser/front-core';
import { Inject } from '@plugin/inversify';
import { EModal } from '@contract/modal';
import { Alert } from '@component/Alert';
import { UserComServiceType } from '@service/usercom';
import { ConnectorErrors } from '@exception/connector-errors';
import { AuthRepositoryType, GrantType } from '../../contracts';
import { LoginIntention, RegisterIntention } from '../../intentions';
import { activationRequiredOAuthProviders } from './OAuth.contracts';
let OAuth = class OAuth extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.errors = null;
        this.email = '';
        this.agreement = false;
        this.newsletter = false;
        this.code = null;
        this.token = null;
        this.provider = null;
    }
    /**
     * Determines whether current route has a `code` query param.
     */
    get hasCode() {
        return this.$route.query ? !!this.$route.query.code : false;
    }
    /**
     * Determines whether current route has a `token` query param.
     */
    get hasToken() {
        return this.$route.query ? !!this.$route.query.token : false;
    }
    get isLogInProcess() {
        return this.process === 'login';
    }
    get isRegisterProcess() {
        return this.process === 'register';
    }
    get isLoggedIn() {
        return this.$store.getters['profile/isUserLoggedIn'];
    }
    get process() {
        var _a;
        if (typeof window !== 'undefined') {
            return localStorage ? ((_a = localStorage.getItem('oauthProcess')) !== null && _a !== void 0 ? _a : '') : '';
        }
    }
    /**
     * Logs In with the code of the provider.
     */
    async logInWithProviderCode() {
        var _a, _b;
        this.isLoading = true;
        try {
            if (this.process === 'login') {
                const user = await this.authRepository.logIn(new LoginIntention({
                    grantType: (_a = this.provider) !== null && _a !== void 0 ? _a : undefined,
                    code: (_b = this.code) !== null && _b !== void 0 ? _b : undefined,
                    remember: true // Default set to true.
                }));
                this.setAuthData(user);
            }
        }
        catch (e) {
            if (e instanceof ResourceActionFailed) {
                if (e.status === ConnectorErrors.Unauthenticated) {
                    this.modalConnector.open(EModal.NoAccountModal);
                }
                if (e.status === ConnectorErrors.Forbidden) {
                    localStorage.setItem('oauthProcess', 'register');
                    this.$router.push({ name: 'profile.oauth', query: { token: e.payload.token } });
                }
                this.$logger(e.message, 'warn');
            }
        }
        finally {
            this.isLoading = false;
        }
    }
    /**
     * Logs In with the code of the provider.
     */
    async registerWithProviderCodeOrToken() {
        var _a;
        this.isLoading = true;
        if (!this.provider) {
            return;
        }
        const token = (this.hasToken && this.token) ? this.token : undefined;
        const code = (this.hasCode && this.code) ? this.code : undefined;
        try {
            const user = await this.authRepository.register(new RegisterIntention({
                grantType: (_a = this.provider) !== null && _a !== void 0 ? _a : undefined,
                code,
                remember: true,
                token,
                email: this.provider === GrantType.Twitter ? this.email : undefined,
                extra: {
                    agreement: this.agreement,
                    newsletter: this.newsletter
                }
            }));
            if (activationRequiredOAuthProviders.includes(this.provider)) {
                this.modalConnector.open(EModal.ActivationLinkSent, {
                    shouldRedirect: true
                });
                if (this.newsletter) {
                    this.userComService.subscribe({ email: this.email }).then(() => {
                        this.eventBus.emit('newsletter-subscribe', {
                            ...{ email: this.email },
                            userId: user.getUser().id
                        });
                    });
                }
            }
            else {
                this.setAuthData(user);
                await this.considerRedirect();
            }
        }
        catch (e) {
            if (e instanceof ResourceActionFailed) {
                if (e.status === ConnectorErrors.Forbidden) {
                    this.modalConnector.open(EModal.NoAccountModal);
                }
                else {
                    this.errors = e.message;
                    this.$logger(e.message, 'warn');
                }
            }
        }
        finally {
            this.isLoading = false;
        }
    }
    /**
     * Determines the function that decides whether to redirect.
     */
    async considerRedirect() {
        var _a;
        const rawLocationPath = (_a = localStorage.getItem('originUrl')) !== null && _a !== void 0 ? _a : '';
        window.location.href = rawLocationPath;
    }
    /**
     * Determines the function that set's found code.
     */
    setCode() {
        var _a;
        this.code = this.$route.query ? ((_a = this.$route.query.code.toString()) !== null && _a !== void 0 ? _a : null) : null;
    }
    /**
     * Determines the function that set's found code.
     */
    setToken() {
        var _a;
        this.token = this.$route.query ? ((_a = this.$route.query.token.toString()) !== null && _a !== void 0 ? _a : null) : null;
    }
    setProvider() {
        const provider = localStorage.getItem('oauthProvider');
        switch (provider) {
            case 'google':
                this.provider = GrantType.Google;
                break;
            case 'facebook':
                this.provider = GrantType.Facebook;
                break;
            case 'linkedin':
                this.provider = GrantType.LinkedIn;
                break;
            case 'twitter':
                this.provider = GrantType.Twitter;
                break;
        }
    }
    setAuthData(user) {
        this.authService.setUser(user.getUser());
        this.authService.setToken(user.toAuthToken());
        this.$store.dispatch('profile/setMe', user.getUser());
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            LocalStorage.set('d24-user-id', user.getUser().uniqueId);
            LocalStorage.set('d24-client-id', user.getUser().id);
            LocalStorage.set('d24-user-com-id', user.getUser().userComId);
            LocalStorage.set('d24-client-sub', user.getUser().subscriptionType);
        }
        this.eventBus.emit('auth:logged-in', user.getUser());
        if (this.isRegisterProcess) {
            if (this.newsletter) {
                this.userComService.subscribe({ email: user.getUser().email }).then(() => {
                    this.eventBus.emit('newsletter-subscribe', {
                        ...{ email: user.getUser().email },
                        userId: user.getUser().id
                    });
                });
            }
        }
    }
    /**
     * TODO: Use it.
     */
    flushOAuthRelatedLocalStorageData() {
        localStorage.removeItem('oauthProcess');
        localStorage.removeItem('originUrl');
    }
    onIsLoggedIn(value) {
        if (value) {
            this.considerRedirect();
        }
    }
};
__decorate([
    Inject(AuthRepositoryType)
], OAuth.prototype, "authRepository", void 0);
__decorate([
    Inject(ModalType)
], OAuth.prototype, "modalConnector", void 0);
__decorate([
    Inject(AuthServiceType)
], OAuth.prototype, "authService", void 0);
__decorate([
    Inject(EventbusType)
], OAuth.prototype, "eventBus", void 0);
__decorate([
    Inject(UserComServiceType)
], OAuth.prototype, "userComService", void 0);
__decorate([
    Watch('isLoggedIn')
], OAuth.prototype, "onIsLoggedIn", null);
OAuth = __decorate([
    Component({
        name: 'OAuth',
        components: {
            Alert
        },
        mounted() {
            if (typeof window !== 'undefined') {
                this.setProvider();
            }
            if (this.authService.check()) {
                this.$router.push('/');
            }
            if (this.hasCode) {
                this.setCode();
            }
            if (this.hasToken) {
                this.setToken();
            }
            if (this.isLogInProcess) {
                this.logInWithProviderCode();
            }
        }
    })
], OAuth);
export { OAuth };
export default OAuth;
